// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 21. Preloader */
.PageLoader_preloader__Unzrc {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030b15;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.PageLoader_spinner__Jb\\+wg {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.PageLoader_spinner__Jb\\+wg > div {
  background-color: #00c2f2;
  height: 100%;
  width: 6px;
  display: inline-block;
  animation: PageLoader_sk-stretchdelay__VXKDY 1.2s infinite ease-in-out;
}
.PageLoader_spinner__Jb\\+wg .PageLoader_rect2__3th3S {
  animation-delay: -1.1s;
}
.PageLoader_spinner__Jb\\+wg .PageLoader_rect3__qC5pI {
  animation-delay: -1s;
}
.PageLoader_spinner__Jb\\+wg .PageLoader_rect4__zwV1n {
  animation-delay: -0.9s;
}
.PageLoader_spinner__Jb\\+wg .PageLoader_rect5__BBfJy {
  animation-delay: -0.8s;
}
@keyframes PageLoader_sk-stretchdelay__VXKDY {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PageLoader/PageLoader.module.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,qBAAqB;EAErB,sEAAoD;AACtD;AACA;EAEE,sBAAsB;AACxB;AACA;EAEE,oBAAoB;AACtB;AACA;EAEE,sBAAsB;AACxB;AACA;EAEE,sBAAsB;AACxB;AAYA;EACE;;;IAGE,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,oBAAoB;IACpB,4BAA4B;EAC9B;AACF","sourcesContent":["/* 21. Preloader */\n.preloader {\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #030b15;\n  z-index: 999;\n  width: 100%;\n  height: 100%;\n}\n.spinner {\n  margin: 100px auto;\n  width: 50px;\n  height: 40px;\n  text-align: center;\n  font-size: 10px;\n}\n.spinner > div {\n  background-color: #00c2f2;\n  height: 100%;\n  width: 6px;\n  display: inline-block;\n  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;\n  animation: sk-stretchdelay 1.2s infinite ease-in-out;\n}\n.spinner .rect2 {\n  -webkit-animation-delay: -1.1s;\n  animation-delay: -1.1s;\n}\n.spinner .rect3 {\n  -webkit-animation-delay: -1s;\n  animation-delay: -1s;\n}\n.spinner .rect4 {\n  -webkit-animation-delay: -0.9s;\n  animation-delay: -0.9s;\n}\n.spinner .rect5 {\n  -webkit-animation-delay: -0.8s;\n  animation-delay: -0.8s;\n}\n@-webkit-keyframes sk-stretchdelay {\n  0%,\n  40%,\n  100% {\n    -webkit-transform: scaleY(0.4);\n  }\n\n  20% {\n    -webkit-transform: scaleY(1);\n  }\n}\n@keyframes sk-stretchdelay {\n  0%,\n  40%,\n  100% {\n    transform: scaleY(0.4);\n    -webkit-transform: scaleY(0.4);\n  }\n\n  20% {\n    transform: scaleY(1);\n    -webkit-transform: scaleY(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preloader": `PageLoader_preloader__Unzrc`,
	"spinner": `PageLoader_spinner__Jb+wg`,
	"sk-stretchdelay": `PageLoader_sk-stretchdelay__VXKDY`,
	"rect2": `PageLoader_rect2__3th3S`,
	"rect3": `PageLoader_rect3__qC5pI`,
	"rect4": `PageLoader_rect4__zwV1n`,
	"rect5": `PageLoader_rect5__BBfJy`
};
export default ___CSS_LOADER_EXPORT___;
